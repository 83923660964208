import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  insertMap, deleteMap } from "../actions";

export interface MapData {
  id?: string;
  name ?: string;
  typeName ?: string;
  description ?: string;
  difficulty ?: string;
  size ?: string;
  mapType ?: string;
  image?:  any;
}
interface MapListState {
  maps: MapData[];
  loading: boolean;
  error: string | null;
}

interface ResponseData {
  success: boolean;
  data: MapData[];
  message: string;
}

const initialState: MapListState = {
  maps: [],
  loading: false,
  error: null,
};

const mapListSlice = createSlice({
  name: "mapList",
  initialState,
  reducers: {},  // Add synchronous reducers here if needed
  extraReducers: (builder) => {
    builder.addCase(insertMap.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(insertMap.fulfilled, (state, action) => {
      state.loading = false;
      state.maps = action.payload;
    });
    builder.addCase(insertMap.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteMap.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteMap.fulfilled, (state, action) => {
      state.loading = false;
      state.maps = action.payload;
    });
    builder.addCase(deleteMap.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default mapListSlice.reducer;
