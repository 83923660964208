import requests from "../Axios/Instance";

// Define interfaces for request and response types
export interface GameModeRequestData {
    id: string,
    modeId: string,
    mode: string,
    settings: {
        maxPlayers: number,
        battleRoyaleSquadSize: number,
        multiplayerSquadSize: number
    }
}



export interface GameModeResponseData {
    success: boolean;
    data: any;
    message: string;
}

// Define TournamentServices for API calls
const GameModeServices = {
    insertGameMode: async (data: GameModeRequestData) => {
        return requests.post(`/game/insertGameMode`, data);
    },
    deleteGameMode: async (data: { gameId: string, modeId: string }) => {
        return requests.delete(`/game/deleteGameMode?gameId=${data.gameId}&modeId=${data.modeId}`);
    },
    
};

export default GameModeServices;

