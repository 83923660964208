import { getActiveElement } from "formik";
import requests from "../Axios/Instance";

export interface GameData {
    typeName?: string;
    categoryId?: string;
    name?: string;
    description?: string;
    developer?: string;
    releaseDate?: string;
    publisher?: string;
    officialWebsite?: string;
    platforms?: string[];
    image?: string;
   
 
}
 

const GameListServices = {
    // Create a new rule by passing title and description dynamically
    categoryList: async (data: {skip: number, limit: number}) => {
        return requests.get('/game/categoryList', { params: data });
    },

    createGame: async (gameData: { typeName: string; name: string; description: string; developer: string; releaseDate: string; publisher: string; officialWebsite: string; platforms: string[]; image: string;   }) => {
        return requests.post('/game/createGame', gameData,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    
    // Get a rule by its dynamic search and id
    getGame: async (data: {name: string; id: string}) => {
        return requests.get(`/game/getGame`, { params: data });
    },

    // Edit a rule by passing dynamic ID and updated rule data
    editGame: async ( gameData: { id: string; typeName: string; name: string; description: string; developer: string; releaseDate: string; publisher: string; officialWebsite: string; platforms: string[]; image: string;  }) => {
        return requests.put('/game/updateGame', gameData,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    // Delete a rule by passing its dynamic ID
    deleteGame: async (gameData: { id: string }) => {
        return requests.delete(`/game/deleteGame?id=${gameData.id}`);
    },
    getGameById: async (data: { id: string }) => {
        return requests.get(`/game/getGame`, { params: data });
    },
};

export default GameListServices;
