import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllTournamentsList } from "../actions";

// Define the request data type for tournaments
export interface AllTournamentListRequestData {
  skip: number;
  limit: number;
  sort?: {
    createdAt?: number;
  };
  search?: string;
  city?: string;
  verified?:string;
 
}



// Define the state for tournaments
interface TournamentListState {
  TournamentList: AllTournamentListRequestData[];
  countDocuments: number;
  loading: boolean;
  error: string | null;
}

// Initialize the state
const initialState: TournamentListState = {
  TournamentList: [],
  countDocuments: 0,
  loading: false,
  error: null,
};

// Create the slice
const tournamentSlice = createSlice({
  name: "tournamentList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTournamentsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAllTournamentsList.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: AllTournamentListRequestData[];
            countDocuments: number;
          }>
        ) => {
          state.loading = false;
          state.TournamentList = action.payload.data;
          state.countDocuments = action.payload.countDocuments;
        }
      )
      .addCase(getAllTournamentsList.rejected, (state, action) => {
        state.loading = false;
        // Error could either be an object or a string, handle accordingly
        state.error =
          typeof action.payload === "string"
            ? action.payload
            : action.payload?.message || "Failed to fetch tournament list";
      });
  },
});

export default tournamentSlice.reducer;
