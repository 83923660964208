import requests from "../Axios/Instance";

// Define interfaces for request and response types
export interface AllTournamentListRequestData {
    skip: number;
    limit: number;
    sort?: {
        createdAt?: number;

    };
    search?: string;
    city?: string;
    communityId?: string;
    userId?: string;
    verified?:string;
}

export interface UpdateTournamentRequestData {
    tournamentId: string;
    type: string;
}

export interface TournamentListRequestData {
    id: string;
    skip: number;
    limit: number;
}

export interface TournamentListResponseData {
    success: boolean;
    data: any;
    message: string;
}

// Define TournamentServices for API calls
const TournamentListServices = {
    getAllTournamentsList: async (data: AllTournamentListRequestData) => {
        return requests.post(`/tournament/getTournament`, data);
    },
    getTournamentsList: async (data: TournamentListRequestData) => {
        return requests.post(`/tournament/getTournament`, data);
    },
    updateTournament: async (data: UpdateTournamentRequestData) => {
        return requests.put(`/tournament/updateTournament`, data);
    }
};

export default TournamentListServices;
