import { createSlice ,PayloadAction } from "@reduxjs/toolkit";
import {  categoryList, GameData } from "../actions";

 
interface GameListState {
  games: GameData[];
  loading: boolean;
  error: string | null;
}

interface ResponseData {
  success: boolean;
  data: GameData[];  
  message: string;
}

const initialState: GameListState = {
  games: [],
  loading: false,
  error: null,
};

const categoryListSlice = createSlice({
  name: "categoryList",
  initialState,
  reducers: {},  // Add synchronous reducers here if needed
  extraReducers: (builder) => {
    // Handle Create Game
    builder.addCase(categoryList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(categoryList.fulfilled, (state, action: PayloadAction<ResponseData>) => {
      state.loading = false;
      state.games = action.payload.data;
    });
    builder.addCase(categoryList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default categoryListSlice.reducer;
