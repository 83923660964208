import { getActiveElement } from "formik";
import requests from "../Axios/Instance";

export interface FrameData {
    name?: string;
    typeName?: string;
    image?: any;

}


const FrameServices = {
    // Create a new rule by passing title and description dynamically
    insertFrame: async (data: FrameData) => {
        return requests.post('/frame/addFrame', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    updateFrame: async (data: { id: string, typeName: string, name: string, image: any }) => {
        return requests.put(`/frame/editFrame`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }); 
    },
    deleteFrame: async (data: { id: string }) => {
        return requests.delete(`/frame/deleteFrame?id=${data.id}`);
    },
    getFrame: async (data: {  skip: number, limit: number,   search: string, sort: { name: number } }) => {
        return requests.post(`/frame/getFrame`, { data });
    },

};

export default FrameServices;
