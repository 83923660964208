import { getActiveElement } from "formik";
import requests from "../Axios/Instance";

export interface AvatarData {
    typeName?: string;
    name?: any;

}


const AvtarServices = {
    // Create a new rule by passing title and description dynamically
    insertAvatar: async (data: AvatarData) => {
        return requests.post('/user/addAvatar', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    updateAvatar: async (data: { _id: string, typeName: string, name: any }) => {
        return requests.put(`/user/editAvatar`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    deleteAvatar: async (data: { _id: string }) => {
        return requests.delete(`/user/deleteAvatar`, { data });
    },
    getAvatar: async (params: {   }) => {
        return requests.get(`/user/getAvatar`, { params });
    },

};

export default AvtarServices;
