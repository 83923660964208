import { getActiveElement } from "formik";
import requests from "../Axios/Instance";

export interface FrameListData {
    search: string;
    sort: {
        name: number;
    };
    skip: number;
    limit: number;

}


const FrameListServices = {
    getFrameList: async (params: FrameListData) => {
        return requests.post(`/frame/getFrame`, params); // Send params directly
    },

};

export default FrameListServices;
