import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { insertAvatar, deleteAvatar , updateAvatar, getAvatar } from "../actions";

export interface AvatarData {
    typeName?: string;
    name?: any;

}
interface AvatarState {
    avatars: AvatarData[];
    loading: boolean;
    error: string | null;
    avatar: AvatarData | null;
}

interface ResponseData {
    success: boolean;
    data: AvatarData[];
    message: string;
}

const initialState: AvatarState = {
    avatars: [],
    loading: false,
    error: null,
    avatar: null,
};

const avatarSlice = createSlice({
    name: "avatar",
    initialState,
    reducers: {},  // Add synchronous reducers here if needed
    extraReducers: (builder) => {
        builder.addCase(insertAvatar.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(insertAvatar.fulfilled, (state, action) => {
            state.loading = false;
            state.avatars = action.payload;
        });
        builder.addCase(insertAvatar.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
        builder.addCase(updateAvatar.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateAvatar.fulfilled, (state, action) => {
            state.loading = false;
            state.avatars = action.payload;
        });
        builder.addCase(updateAvatar.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
        builder.addCase(deleteAvatar.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteAvatar.fulfilled, (state, action) => {
            state.loading = false;
            state.avatars = action.payload;
        });
        builder.addCase(deleteAvatar.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
        builder.addCase(getAvatar.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAvatar.fulfilled, (state, action) => {
            state.loading = false;
            state.avatars = action.payload?.data;
        });
        builder.addCase(getAvatar.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    },
});

export default avatarSlice.reducer;
