import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { insertFrame, deleteFrame, updateFrame,  } from "../actions";

export interface FrameData {
    typeName?: string;
    name?: string;
    image?: any;
}
interface FrameState {
    frames: FrameData[];
    loading: boolean;
    error: string | null;
    frame: FrameData | null;
}

interface ResponseData {
    success: boolean;
    data: FrameData[];
    message: string;
}

const initialState: FrameState = {
    frames: [],
    loading: false,
    error: null,
    frame: null,
};

const FrameSlice = createSlice({
    name: "frame",
    initialState,
    reducers: {},  // Add synchronous reducers here if needed
    extraReducers: (builder) => {
        builder.addCase(insertFrame.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(insertFrame.fulfilled, (state, action) => {
            state.loading = false;
            state.frames = action.payload;
        });
        builder.addCase(insertFrame.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
        builder.addCase(updateFrame.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateFrame.fulfilled, (state, action) => {
            state.loading = false;
            state.frame = action.payload;
        });
        builder.addCase(updateFrame.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
        builder.addCase(deleteFrame.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteFrame.fulfilled, (state, action) => {
            state.loading = false;
            state.frame = action.payload;
        });
        builder.addCase(deleteFrame.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    },
});

export default FrameSlice.reducer;
