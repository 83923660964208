import { createSlice } from "@reduxjs/toolkit";
import { insertGameMode, deleteGameMode } from "../actions";


export interface GameModeRequestData {
    id: string;
    modeId: string;
    mode: string;
    settings: {
        maxPlayers: number;
        battleRoyaleSquadSize: number;
        multiplayerSquadSize: number;
    };
}

interface GameModeState {
    gameMode: GameModeRequestData[];
    loading: boolean;
    error: string | null;
}

interface ResponseData {
    success: boolean;
    data: GameModeRequestData[];
    message: string;
}

// Initialize the state
const initialState: GameModeState = {
    gameMode: [],
    loading: false,
    error: null,
};

const GameModeSlice = createSlice({
    name: "gameMode",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(insertGameMode.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(insertGameMode.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.gameMode = action.payload.data; // Use the correct data structure here
            })
            .addCase(insertGameMode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(deleteGameMode.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteGameMode.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.gameMode = action.payload.data; // Use the correct data structure here
            })
            .addCase(deleteGameMode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default GameModeSlice.reducer;
