import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createGame, getGame, editGame, deleteGame, categoryList, getGameById } from "../actions";

export interface Map {
  name: string;
  // additional properties specific to a map
}

export interface GameData {
  typeName: string;
  categoryId: string;
  name: string;
  description: string;
  developer: string;
  releaseDate: string;
  publisher: string;
  officialWebsite: string;
  platforms: string[];
  image: string;
  maps: Map[]; // maps as part of the game data
}

interface GameListState {
  games: GameData[];
  loading: boolean;
  error: string | null;
}

interface ResponseData {
  success: boolean;
  data: GameData[];
  message: string;
}

const initialState: GameListState = {
  games: [],
  loading: false,
  error: null,
};

const gameSlice = createSlice({
  name: "games",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createGame.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createGame.fulfilled, (state, action: PayloadAction<ResponseData>) => {
      state.loading = false;
      if (action.payload.success) {
          // Don't try to spread the data if it's not an array
          if (Array.isArray(action.payload.data)) {
              state.games = [...state.games, ...action.payload.data];
          } else if (action.payload.data) {
              state.games = [...state.games, action.payload.data];
          }
      }
  });
    builder.addCase(createGame.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An error occurred while creating the game.";
    });

    builder.addCase(getGame.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGame.fulfilled, (state, action: PayloadAction<ResponseData>) => {
      state.loading = false;
      if (action.payload.success) {
        state.games = action.payload.data;
      } else {
        state.error = action.payload.message || "Failed to fetch games.";
      }
    });
    builder.addCase(getGame.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An error occurred while fetching games.";
    });

    builder.addCase(editGame.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(editGame.fulfilled, (state, action: PayloadAction<ResponseData>) => {
      state.loading = false;
      if (action.payload.success) {
        const updatedGame = action.payload.data[0]; // Assuming single edited game
        const index = state.games.findIndex((game) => game.name === updatedGame.name);
        if (index !== -1) {
          state.games[index] = updatedGame;
        }
      } else {
        state.error = action.payload.message || "Failed to update game.";
      }
    });
    builder.addCase(editGame.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An error occurred while updating the game.";
    });

    builder.addCase(deleteGame.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteGame.fulfilled, (state, action) => {
      state.loading = false;
      state.games = action.payload; // Reset rule after deletion
  });
    builder.addCase(deleteGame.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An error occurred while deleting the game.";
    });

    builder.addCase(getGameById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGameById.fulfilled, (state, action: PayloadAction<ResponseData>) => {
      state.loading = false;
      if (action.payload.success) {
        state.games = action.payload.data; // Assuming data contains details for one game
      } else {
        state.error = action.payload.message || "Failed to fetch game details.";
      }
    });
    builder.addCase(getGameById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An error occurred while fetching game details.";
    });
  },
});

export default gameSlice.reducer;
