import { configureStore } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import authReducer from './Slice/authSlice';  
import PlayerListSliceReducer from './Slice/PlayerListSlice';  
import communityListSliceReducer from './Slice/communityListSlice';  
import AdvertisementSliceReducer from './Slice/AdvertisementSlice'; 
import SelectedPlayerSliceReducer from './Slice/SelectedPlayerSlice'; 
import SelectedCommunitySliceReducer from './Slice/SelectedCommunitySlice'; 
import DashboardSliceReducer from './Slice/DashboardSlice'; 
import communityDetailsSliceReducer from './Slice/communityDetailsSlice'; 
import AdminTeamSliceReducer from './Slice/AdminTeamSlice'; 
import TournamentSliceReducer from './Slice/TournamentSlice'; 
import FollowerFollowingSliceReducer from './Slice/FollowerFollowingSlice'; 
import PlayersDetailsSliceReducer from './Slice/PlayersDetailsSlice';
import UserPlayerSliceReducer from './Slice/UserPlayerSlice';
import FollowerFollowingPlayersSliceReducer from './Slice/FollowerFollowingPlayersSlice';
import FollowerFollowingListSliceSliceReducer from './Slice/FollowerFollowingListSlice';
import AgeGenderStatisticsSliceReducer from './Slice/AgeGenderStatisticsSlice';
import TopCitiesWithHighestSliceReducer from './Slice/TopCitiesWithHighestSlice';
import TournamentListSliceReducer from './Slice/TournamentListSlice';
import TournamentPointsTableSliceReducer from './Slice/TournamentPointsTableSlice';
import selectedTournamentsListSliceReducer from './Slice/selectedTournamentsListSlice';
import TouenamentPrizeCardSliceReducer from './Slice/TouenamentPrizeCardSlice';
import AllJoinTeamsOfTournamentSliceReducer from './Slice/AllJoinTeamsOfTournamentSlice';
import RuleSliceReducer from './Slice/RuleSlice';
import SponsorshipSliceReducer from './Slice/SponsorshipSlice';
import gameListSliceReducer from './Slice/gameListSlice';
import categoryListSliceReducer from './Slice/categoryListSlice';
import mapListSliceReducer from './Slice/mapListSlice';
import avatarSliceReducer from './Slice/AvtarSlice';
import FrameSliceReducer from './Slice/FrameSlice';
import FrameListSliceReducer from './Slice/FrameListSlice';
import UpdateTournamentSliceReducer from './Slice/UpdateTournament';
import GameModeSliceReducer from './Slice/GameModeSlice';

const store = configureStore({
    reducer: {
        AuthLogin: authReducer,
        playerList:PlayerListSliceReducer,
        communityList:communityListSliceReducer,
        advertisements:AdvertisementSliceReducer,
        selectedPlayer:SelectedPlayerSliceReducer,
        selectedcommunityList:SelectedCommunitySliceReducer,
        dashboard:DashboardSliceReducer,
        communityDetails:communityDetailsSliceReducer,
        adminTeam:AdminTeamSliceReducer,
        tournament:TournamentSliceReducer,
        followerFollowing:FollowerFollowingSliceReducer,
        playersDetails:PlayersDetailsSliceReducer,
        userplayer:UserPlayerSliceReducer,
        followerFollowingPlayers:FollowerFollowingPlayersSliceReducer,
        followerFollowingList:FollowerFollowingListSliceSliceReducer,
        ageGenderStatistics:AgeGenderStatisticsSliceReducer,
        topCitiesWithHighest:TopCitiesWithHighestSliceReducer,
        tournamentList:TournamentListSliceReducer,
        tournamentPointsTable:TournamentPointsTableSliceReducer,
        SelectedTournamentList:selectedTournamentsListSliceReducer,
        tournamentPrizeCard:TouenamentPrizeCardSliceReducer,
        allJoinTeamsOfTournament:AllJoinTeamsOfTournamentSliceReducer,
        rule:RuleSliceReducer,
        sponsorship:SponsorshipSliceReducer,
        categoryList:categoryListSliceReducer,
        gameList:gameListSliceReducer,
        mapList:mapListSliceReducer,
        avatar:avatarSliceReducer,
        frame:FrameSliceReducer,
        frameList:FrameListSliceReducer,
        updateTournament:UpdateTournamentSliceReducer,
        gameMode:GameModeSliceReducer,

    },
});
export type AppDispatch = ThunkDispatch<RootState, void, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;

export default store;
