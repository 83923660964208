import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {   getFrameList } from "../actions";

export interface FrameListData {
    search: string;
    skip: number;
    limit: number;
    sort: {
        name: number;
    };
}
interface FrameListState {
    frameList: FrameListData[];
    loading: boolean;
    error: string | null;
    frame: FrameListData | null;
}

interface ResponseData {
    success: boolean;
    data: FrameListData[];
    message: string;
}

const initialState: FrameListState  = {
    frameList: [],
    loading: false,
    error: null,
    frame: null,
};

const FrameListSlice = createSlice({
    name: "frameList",
    initialState,
    reducers: {},  // Add synchronous reducers here if needed
    extraReducers: (builder) => {
        builder.addCase(getFrameList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getFrameList.fulfilled, (state, action) => {
            state.loading = false;
            state.frameList = action.payload?.data;
        });
        builder.addCase(getFrameList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    },
});

export default FrameListSlice.reducer;

