import { createSlice } from "@reduxjs/toolkit";
import { updateTournament } from "../actions";

// Define the state for tournaments
interface UpdateTournamentState {
    tournaments: any[];
    loading: boolean;
    error: string | null;
}

interface ResponseData {
    success: boolean;
    data: any[];
    message: string;
}

// Initialize the state
const initialState: UpdateTournamentState = {
    tournaments: [],
    loading: false,
    error: null,
};

const UpdateTournamentSlice = createSlice({
    name: "updateTournament",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateTournament.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTournament.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.tournaments = action.payload.data; // Use the correct data structure here
            })
            .addCase(updateTournament.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default UpdateTournamentSlice.reducer;
