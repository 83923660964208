import { getActiveElement } from "formik";
import requests from "../Axios/Instance";

export interface MapData {
    id?: string;
    name ?: string;
    typeName ?: string;
    description ?: string;
    difficulty ?: string;
    size ?: string;
    mapType ?: string;
    image?: any;
 
}
 

const MapListServices = {
    // Create a new rule by passing title and description dynamically
    insertMap: async (data: MapData) => {
        return requests.post('/game/insertMap', data,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    deleteMap: async (gameData: { id: string , name: string }) => {
        return requests.delete(`/game/deleteMap?id=${gameData.id}&name=${gameData.name}`);
    },
    
};

export default MapListServices;
